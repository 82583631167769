<template>
	<router-link :to="to">
		<button
			:class="[
				'v-btn',
				buttonIconOnlyClass,
				`v-btn-color-${buttonColor}`,
				{ 'w-100': block },
				{ 'v-btn-loading': loading },
				strongShadow ? 'shadow-4' : mediumShadow ? 'shadow-3' : noShadow ? '' : 'shadow-2',
				noRadius ? '' : softRadius ? 'border-radius' : 'button-border-radius',
				this.class
			]"
			:style="style"
			:disabled="disabled"
			@click.prevent="clicked"
		>
			<template v-if="!loading">
				<v-svg
					alt="Button Icon"
					:icon="icon"
					:class="iconOnly ? 'v-btn-icon-solo' : 'v-btn-icon'"
					:size="iconOnly ? (small ? 14 : 17) : 13"
					:custom="customIcon"
					:black="blackIcon"
					v-if="icon !== ''"
				/>
				<slot v-if="!iconOnly" />
			</template>
			<template v-else>
				<v-spinner thin :size="spinnerSize" :color="spinnerColor" class="v-btn-spinner" :class="spinnerSizeClass" />
				{{ loadingText }}
			</template>
		</button>
	</router-link>
</template>

<script>
import VSvg from '@/components/VSvg'
import VSpinner from '@/components/VSpinner'

export default {
	name: 'VBtn',
	components: { VSvg, VSpinner },
	props: {
		small: {
			type: Boolean,
			default: false
		},
		large: {
			type: Boolean,
			default: false
		},
		to: {
			type: [String, Object],
			default: ''
		},
		block: {
			type: Boolean,
			default: false
		},
		iconOnly: {
			type: Boolean,
			default: false
		},
		noShadow: {
			type: Boolean,
			default: false
		},
		mediumShadow: {
			type: Boolean,
			default: false
		},
		strongShadow: {
			type: Boolean,
			default: false
		},
		success: {
			type: Boolean,
			default: false
		},
		error: {
			type: Boolean,
			default: false
		},
		warning: {
			type: Boolean,
			default: false
		},
		info: {
			type: Boolean,
			default: false
		},
		white: {
			type: Boolean,
			default: false
		},
		lightGrey: {
			type: Boolean,
			default: false
		},
		grey: {
			type: Boolean,
			default: false
		},
		icon: {
			type: String,
			default: ''
		},
		customIcon: {
			type: Boolean,
			default: false
		},
		blackIcon: {
			type: Boolean,
			default: false
		},
		class: {
			type: String,
			default: ''
		},
		style: {
			type: String,
			default: ''
		},
		loading: {
			type: Boolean,
			default: false
		},
		loadingText: {
			type: String,
			default: 'Loading...'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		softRadius: {
			type: Boolean,
			default: false
		},
		noRadius: {
			type: Boolean,
			default: false
		}
	},
	emits: { click: null },
	methods: {
		clicked() {
			if (!this.loading) {
				if (this.to !== '') {
					this.$router.push(this.to)
					return false
				}

				this.$emit('click')
			}
		}
	},
	computed: {
		buttonIconOnlyClass() {
			return this.iconOnly && this.small
				? 'v-btn-icon-only-small'
				: this.iconOnly
				? 'v-btn-icon-only'
				: !this.small && !this.large
				? 'v-btn-medium'
				: this.small
				? 'v-btn-small'
				: this.large
				? 'v-btn-large'
				: ''
		},
		buttonColor() {
			return this.white
				? 'white'
				: this.lightGrey
				? 'light-grey'
				: this.grey
				? 'grey'
				: this.success
				? 'success'
				: this.info
				? 'info'
				: this.warning
				? 'warning'
				: this.error
				? 'error'
				: 'black'
		},
		spinnerColor() {
			return this.white ? '#1a1a1a' : '#fff'
		},
		spinnerSize() {
			return !this.small && !this.large ? 14 : this.small ? 12 : 16
		},
		spinnerSizeClass() {
			return !this.small && !this.large ? 'v-btn-spinner-medium' : this.small ? 'v-btn-spinner-small' : 'v-btn-spinner-large'
		}
	}
}
</script>

<style lang="scss">
@import 'src/scss/variables';

.v-btn {
	cursor: pointer;
	border: none;
	text-transform: uppercase;
	transition: all 150ms ease-in-out;
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	user-select: none;
}

.v-btn-color-black {
	background-color: $primary;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: transparentize($primary, 0.25);
	}
}

.v-btn-color-white {
	background-color: $white;
	color: $primary;

	&:not(.v-btn-loading):hover {
		background-color: darken($white, 6%);
	}
}

.v-btn-color-light-grey {
	background-color: $light-grey;
	color: $primary;

	&:not(.v-btn-loading):hover {
		background-color: darken($light-grey, 6%);
	}
}

.v-btn-color-grey {
	background-color: $darker-grey;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: darken($darker-grey, 6%);
	}
}

.v-btn-color-success {
	background-color: $dark-green;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: darken($dark-green, 6%);
	}
}

.v-btn-color-info {
	background-color: $blue;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: darken($blue, 6%);
	}
}

.v-btn-color-warning {
	background-color: $orange;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: darken($orange, 6%);
	}
}

.v-btn-color-error {
	background-color: $dark-red;
	color: $white;

	&:not(.v-btn-loading):hover {
		background-color: darken($dark-red, 6%);
	}
}

.v-btn-loading {
	cursor: default !important;
}

.v-btn-small {
	font-size: 0.85em;
	padding: 7px 17px;
}

.v-btn-medium {
	font-size: 0.9em;
	padding: 10px 20px;
}

.v-btn-large {
	font-size: 1em;
	padding: 15px 30px;
}

.v-btn-spinner {
	display: inline-block;
	margin-right: 0.9rem;
}

.v-btn-spinner-small {
	top: 0.2rem;
}

.v-btn-spinner-medium {
	top: 0.2rem;
}

.v-btn-spinner-large {
	top: 0.3rem;
}

.v-btn-icon-only {
	padding: 8px 10px;
	margin-bottom: 0.05rem;
}

.v-btn-icon-only-small {
	padding: 3px 7px 5px 7px;
}

.v-btn-icon {
	display: inline-block;
	margin-right: 1rem;
	margin-bottom: 0.15rem;
}

.v-btn-icon-solo {
	margin-right: 0;
}
</style>
