import { SnackbarMessages } from 'vue3-snackbar'
let messageKey = 0

export default {
	success(message, duration = 4000) {
		this._execute('success', message, duration)
	},
	info(message, duration = 4000) {
		this._execute('info', message, duration)
	},
	warning(message, duration = 4000) {
		this._execute('warning', message, duration)
	},
	error(message, duration = 4000) {
		this._execute('error', message, duration)
	},
	_execute(type, text, duration) {
		SnackbarMessages.value.push({ type, text, duration, id: `snackbar-custom-message-${messageKey}` })
		messageKey++
	}
}
