<template>
	<img
		:alt="alt"
		:style="[`width: ${size}px;`, offset ? 'margin-bottom: 2px;' : '', style]"
		:class="this.class"
		:src="require(`@/media/svg/${color}/${icon}-${color}.svg`)"
		@click="this.$emit('clicked')"
	/>
</template>

<script>
export default {
	name: 'VSvg',
	props: {
		icon: {
			type: String,
			default: '',
			required: true
		},
		alt: {
			type: String,
			default: 'Icon'
		},
		size: {
			type: [String, Number],
			default: 20
		},
		class: {
			type: String,
			default: ''
		},
		style: {
			type: String,
			default: ''
		},
		offset: {
			type: Boolean,
			default: false
		},
		black: {
			type: Boolean,
			default: false
		},
		custom: {
			type: Boolean,
			default: false
		}
	},
	emit: ['clicked'],
	computed: {
		color() {
			return this.black ? 'black' : this.custom ? 'custom' : 'white'
		}
	}
}
</script>

<style scoped></style>
