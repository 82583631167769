<template>
	<div :class="this.class">
		<div class="shapla-spinner">
			<div :class="`shapla-spinner__layer shapla-spinner__layer-${index}`" v-for="index in [1, 2, 3, 4]" :key="index">
				<div class="shapla-spinner__circle-clipper shapla-spinner__left">
					<div class="shapla-spinner__circle"></div>
				</div>
				<div class="shapla-spinner__gap-patch">
					<div class="shapla-spinner__circle"></div>
				</div>
				<div class="shapla-spinner__circle-clipper shapla-spinner__right">
					<div class="shapla-spinner__circle"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VSpinner',
	props: {
		size: {
			type: [Number, String],
			default: 24
		},
		thin: {
			type: Boolean,
			default: false
		},
		thickness: {
			type: [Number, String],
			default: 3
		},
		multiColor: {
			type: Boolean,
			default: false
		},
		color: {
			type: String,
			default: '#1a1a1a'
		},
		color2: {
			type: String,
			default: 'red'
		},
		color3: {
			type: String,
			default: 'orange'
		},
		color4: {
			type: String,
			default: 'green'
		},
		class: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			circleSize: `${this.size}px`,
			borderWidth: this.thin ? '2px' : this.thickness + 'px',
			renderedColor2: this.multiColor ? this.color2 : this.color,
			renderedColor3: this.multiColor ? this.color3 : this.color,
			renderedColor4: this.multiColor ? this.color4 : this.color
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'src/scss/variables';
@import 'custom-shapla-css/src/components/spinner/spinner.scss';

.shapla-spinner {
	--spinner-size: v-bind(circleSize);
}

.shapla-spinner__circle {
	border-width: v-bind(borderWidth);
}

.shapla-spinner__layer-1 {
	border-color: v-bind(color);
}

.shapla-spinner__layer-2 {
	border-color: v-bind(renderedColor2);
}

.shapla-spinner__layer-3 {
	border-color: v-bind(renderedColor3);
}

.shapla-spinner__layer-4 {
	border-color: v-bind(renderedColor4);
}
</style>
