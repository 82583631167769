export default {
	__userAccounts: [],

	__setUserAccount(accounts) {
		this.__userAccounts = accounts
	},

	is(account) {
		return Array.isArray(account) ? account.every(x => this.__userAccounts.includes(x)) : this.__userAccounts.includes(account)
	},

	not(account) {
		return Array.isArray(account) ? !account.every(x => this.__userAccounts.includes(x)) : !this.__userAccounts.includes(account)
	},

	either(account) {
		return Array.isArray(account) ? account.some(x => this.__userAccounts.includes(x)) : this.__userAccounts.includes(account)
	},

	neither(account) {
		return Array.isArray(account) ? !account.some(x => this.__userAccounts.includes(x)) : !this.__userAccounts.includes(account)
	},

	only(account) {
		return Array.isArray(account) ? false : this.__userAccounts.includes(account) && this.__userAccounts.length === 1
	}
}
